*,
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none;
}

p {
  line-height: 130%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: black;
}

input,
button,
select,
textarea {
  outline: none;
  border: none;
}

button {
  cursor: pointer;
  background-color: transparent;
}

* {
  transition: all 0.3s ease-in-out;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mt-6 {
  margin-top: 6rem;
}

.mt-7 {
  margin-top: 7rem;
}

.mt-8 {
  margin-top: 8rem;
}

.mt-9 {
  margin-top: 9rem;
}

.mt-10 {
  margin-top: 10rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.mb-6 {
  margin-bottom: 6rem;
}

.mb-7 {
  margin-bottom: 7rem;
}

.mb-8 {
  margin-bottom: 8rem;
}

.mb-9 {
  margin-bottom: 9rem;
}

.mb-10 {
  margin-bottom: 10rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 3rem;
}

.mr-4 {
  margin-right: 4rem;
}

.mr-5 {
  margin-right: 5rem;
}

.mr-6 {
  margin-right: 6rem;
}

.mr-7 {
  margin-right: 7rem;
}

.mr-8 {
  margin-right: 8rem;
}

.mr-9 {
  margin-right: 9rem;
}

.mr-10 {
  margin-right: 10rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 3rem;
}

.ml-4 {
  margin-left: 4rem;
}

.ml-5 {
  margin-left: 5rem;
}

.ml-6 {
  margin-left: 6rem;
}

.ml-7 {
  margin-left: 7rem;
}

.ml-8 {
  margin-left: 8rem;
}

.ml-9 {
  margin-left: 9rem;
}

.ml-10 {
  margin-left: 10rem;
}
