@font-face {
  font-family: "Light";
  src: url(../fonts/Mont-Light.ttf);
}
@font-face {
  font-family: "Regular";
  src: url(../fonts/Mont-Regular.ttf);
}
@font-face {
  font-family: "SemiBold";
  src: url(../fonts/Mont-SemiBold.ttf);
}
@font-face {
  font-family: "Bold";
  src: url(../fonts/Mont-Bold.ttf);
}
html {
  font-size: 10px;
  scroll-behavior: smooth;
}
body {
  font-family: "Regular";
  color: #000;
  scroll-behavior: smooth;
  position: relative;
}
main section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 8rem 0;
  box-sizing: border-box;
  width: 90%;
  margin: 0 auto;
}
main .firstScreen {
  display: flex;
  align-items: center;
  background-image: url(../background/firstSection.webp);
  background-size: contain;
  width: 100%;
}
main .firstScreen .container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  min-height: 60vh;
}
main .firstScreen .container img.logo {
  width: 10rem;
  height: 10rem;
  object-fit: contain;
}
main .firstScreen .container h1 {
  color: #fff;
  font: 4rem "Light";
  line-height: 120%;
  letter-spacing: -0.02em;
  text-align: center;
}
main .firstScreen .container p {
  font: 5rem "Bold";
  line-height: 132%;
  text-align: center;
  color: #fff;
}
main .firstScreen .container a.text {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #e3c496;
  font: 2.4rem "Bold";
}
main .firstScreen .container a.text img {
  width: 5rem;
  object-fit: contain;
  margin-top: 2rem;
}
main .firstScreen .container a.button {
  background-color: #e3c496;
  color: #000;
  padding: 1rem;
  box-sizing: border-box;
  font: 2.1rem "SemiBold";
  border: 0.1rem solid transparent;
}
main .firstScreen .container a.button:hover {
  border-color: #e3c496;
  background-color: transparent;
  color: #fff;
}
main .profit {
  padding: 8rem 0;
  box-sizing: border-box;
  width: 90%;
  margin: 0 auto;
}
main .profit h2 {
  font: 5.2rem "Bold";
  line-height: 110%;
  letter-spacing: -0.03em;
  color: #000;
  margin-bottom: 5rem;
}
main .profit .slide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
main .profit .slick-dots {
  display: none !important;
}
main .profit .slick-dots button {
  width: 10rem;
  height: 1rem;
  background-color: #f9f3ea;
}
main .profit .slick-dots button::before {
  display: none !important;
}
main .profit .slick-dots .slick-active button {
  background-color: #e3c496;
}
main .profit .slick-next {
  right: -5rem !important;
}
main .profit .slick-next::before {
  display: none;
}
main .profit .slick-prev {
  left: -5rem !important;
}
main .profit .slick-prev::before {
  display: none;
}
main .profit .block {
  display: flex !important;
  flex-direction: row-reverse;
  width: 100% !important;
}
main .profit .block img {
  width: 50%;
  object-fit: contain;
}
main .profit .block .textPart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 4rem;
  box-sizing: border-box;
  background-color: #f9f3ea;
}
main .profit .block .textPart h3 {
  font: 2.4rem "Bold";
  line-height: 135%;
  width: 60%;
  margin-bottom: 2rem;
}
main .profit .block .textPart p {
  font: 2rem "Light";
  line-height: 130%;
  width: 60%;
}
main .profit .block:nth-child(2n) {
  flex-direction: row;
}
main .career .container {
  display: flex;
  justify-content: space-between;
}
main .career .container .right {
  width: 60%;
}
main .career .container .right img {
  width: 100%;
  object-fit: contain;
}
main .career .container .left {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
main .career .container .left h2 {
  font: 5.2rem "Bold";
  line-height: 110%;
  letter-spacing: -0.03em;
  margin-bottom: 2rem;
}
main .career .container .left p.background {
  background-color: #e3c496;
  font: 2.4rem "Light";
  padding: 1rem;
  box-sizing: border-box;
}
main .career .container .left h3 {
  margin-top: 4rem;
  font: 2.4rem "Bold";
  margin-bottom: 2rem;
}
main .career .container .left p {
  font: 2rem "Light";
}
main .start {
  width: 90%;
  margin: 0 auto;
}
main .start .container {
  padding: 8rem;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  justify-content: space-between;
  min-height: 45rem;
  background-image: url(../background/start.webp);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  background-color: #333333;
}
main .start .container .left,
main .start .container .right {
  width: 48%;
}
main .start .container .left h2 {
  font: 5.2rem "Bold";
  color: #fff;
  line-height: 110%;
  letter-spacing: -0.03rem;
  margin-bottom: 2rem;
}
main .start .container .left p {
  font: 2.4rem "Light";
  line-height: 135%;
  color: #fff;
}
main .start .container .right p {
  font-size: 2.4rem;
  line-height: 135%;
  color: #fff;
}
main .question {
  position: relative;
}
main .question .row {
  display: flex;
  justify-content: space-between;
}
main .question .row .block {
  width: 49%;
  padding: 2rem;
  box-sizing: border-box;
  background-color: #f9f3ea;
  min-height: 52rem;
}
main .question .row .block .inRow {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 4rem;
}
main .question .row .block .inRow p {
  font-size: 7.2rem;
  line-height: 106%;
  color: #e3c496;
  width: unset;
}
main .question .row .block .inRow p.light {
  font-family: "Light";
}
main .question .row .block h3 {
  font: 4rem "Bold";
  line-height: 135%;
  color: #000;
  margin-bottom: 2rem;
  width: 80%;
}
main .question .row .block p {
  font-size: 2.4rem;
  line-height: 135%;
  width: 80%;
  color: #000;
  font-family: "SemiBold";
}
main .question .row .block p.light {
  font-family: "Light";
}
main .question .block100 {
  display: flex;
  width: 100%;
  min-height: 62.5rem;
  background-color: #333;
  padding: 2.6rem;
  box-sizing: border-box;
  margin-top: 4rem;
}
main .question .block100 .container {
  display: flex;
  justify-content: space-between;
}
main .question .block100 .container .left,
main .question .block100 .container .right {
  width: 48%;
}
main .question .block100 .container .left {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}
main .question .block100 .container .left h3 {
  font: 4rem "Bold";
  line-height: 135%;
  color: #e3c496;
  margin-bottom: 4rem;
}
main .question .block100 .container .left p {
  font: 2.4rem "Light";
  line-height: 135%;
  color: #fff;
  margin-bottom: 2rem;
}
main .question .block100 .container .left a {
  display: flex;
  width: 60%;
  margin-top: 6rem;
  justify-content: center;
  padding: 1.2rem 4.4rem;
  background-color: #e3c496;
  color: #333333;
  font: 2.4rem "SemiBold";
  border: 0.1rem solid transparent;
}
main .question .block100 .container .left a:hover {
  border-color: #e3c496;
  background-color: transparent;
  color: #fff;
}
main .question .block100 .container .right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
main .question .block100 .container .right .inRow {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 4rem;
}
main .question .block100 .container .right .inRow p {
  font-size: 7.2rem;
  line-height: 106%;
  color: #e3c496;
  width: unset;
}
main .question .block100 .container .right .inRow p.light {
  font-family: "Light";
}
main .question .block100 .container .right img {
  width: 100%;
  max-width: 83.5rem;
  object-fit: contain;
}
main .question .absolute {
  position: absolute;
  z-index: 1;
  top: 10rem;
  left: 0;
}
main .history h2 {
  font: 5.2rem "Bold";
  line-height: 110%;
  letter-spacing: -0.03rem;
  text-align: center;
  color: #000;
  margin-bottom: 6rem;
}
main .history .container {
  display: flex;
  justify-content: space-between;
}
main .history .container .column {
  display: flex;
  flex-direction: column;
  width: 32%;
}
main .history .container .column:nth-child(2n) {
  flex-direction: column-reverse;
}
main .history .container .column:nth-child(2n) img {
  width: 100%;
  object-fit: cover;
}
main .history .container .column:nth-child(2n) img:nth-child(1) {
  height: 53rem;
  margin-top: 2rem;
}
main .history .container .column:nth-child(2n) img:nth-child(2) {
  height: 35rem;
}
main .history .container .column img {
  width: 100%;
  object-fit: cover;
}
main .history .container .column img:nth-child(1) {
  height: 53rem;
  margin-bottom: 2rem;
}
main .history .container .column img:nth-child(2) {
  height: 35rem;
}
main .history a {
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 6rem;
  font: 2.6rem "Bold";
  color: #e3c496;
  padding: 0 2rem;
  padding-bottom: 1rem;
  box-sizing: border-box;
  border-bottom: 0.3rem solid #e3c496;
}
main .history a img {
  margin-left: 2rem;
}
main .form {
  min-height: 80vh;
  max-height: 100vh;
  overflow: hidden;
  background-image: url(../background/form-bg.webp);
  background-size: cover;
  justify-content: unset;
  background-repeat: no-repeat;
  width: 100%;
  padding: 0;
}
main .form .container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-left: 5%;
  height: 100%;
  box-sizing: border-box;
}
main .form .container .left,
main .form .container .right {
  width: 40%;
}
main .form .container .left h2 {
  font: 5.2rem "Bold";
  line-height: 110%;
  letter-spacing: -0.03em;
  color: #000;
  margin-bottom: 2rem;
  margin-top: 15rem;
}
main .form .container .left p {
  font-size: 2.4rem;
  line-height: 135%;
  color: #000;
}
main .form .container .right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8rem;
  box-sizing: border-box;
  width: 35%;
  height: 100%;
  background-color: rgba(52, 52, 52, 0.8);
  backdrop-filter: blur(1rem);
}
main .form .container .right form {
  width: 60%;
  display: flex;
  flex-direction: column;
}
main .form .container .right form h3 {
  font: 4rem "Bold";
  color: #fff;
  margin-bottom: 2rem;
}
main .form .container .right form p {
  font: 2.1rem "Light";
  color: #b1b1b1;
}
main .form .container .right form p.light {
  font: 1.7rem "Light";
  color: #fff;
  margin-top: 4rem;
  margin-bottom: 1rem;
}
main .form .container .right form .inputContainer {
  margin-top: 5rem;
}
main .form .container .right form .inputContainer input {
  width: 100%;
  background-color: transparent;
  font-size: 2.1rem;
  padding: 1rem 2rem;
  box-sizing: border-box;
  color: #fff;
  border-bottom: 0.2rem solid #fff;
  margin-bottom: 2rem;
}
main .form .container .right form .inputContainer input::placeholder {
  opacity: 0.5;
  color: #fff;
}
main .form .container .right form .inputContainer .css-13cymwt-control {
  background-color: transparent;
  border-color: transparent;
  border-bottom-color: #fff;
  color: #b1b1b1;
  font-size: 2.1rem;
}
main .form .container .right form .inputContainer .css-t3ipsp-control {
  background-color: transparent;
  border-color: transparent;
  border-bottom-color: #fff;
  color: #b1b1b1;
  font-size: 2.1rem;
  box-shadow: unset;
}
main .form .container .right form .inputContainer .css-t3ipsp-control:hover {
  border-color: transparent;
  border-bottom-color: #fff;
}
main .form .container .right form .inputContainer .css-1wy0on6 {
  display: none;
}
main .form .container .right form .inputContainer .css-1nmdiq5-menu {
  background-color: #4c4c4c;
  color: #fff;
  font-size: 2.1rem;
  border: none;
  box-shadow: none;
}
main .form .container .right form .inputContainer .css-19bb58m,
main .form .container .right form .inputContainer .css-1dimb5e-singleValue {
  color: #fff;
}
main .form .container .right form .inputContainer .css-13cymwt-option {
  display: none;
  background-color: red;
}
main .form .container .right form .inputContainer [class*="option"] {
  color: #fff;
}
main .form .container .right form .inputContainer [class*="option"]:hover {
  background-color: #e3c496;
  color: #000;
}
main .form .container .right form .inputContainer .row {
  display: flex;
  justify-content: center;
  width: 90%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
main .form .container .right form .inputContainer .row input {
  padding: 0;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 2rem;
}
main .form .container .right form .inputContainer .row p {
  font-size: 1.4rem;
  width: 100%;
  color: #fff;
  font-family: "Regular";
}
main .form .container .right form .inputContainer button {
  background-color: #e3c496;
  color: #000;
  padding: 1rem;
  box-sizing: border-box;
  width: 100%;
  font: 2.1rem "SemiBold";
  border: 0.1rem solid transparent;
}
main .form .container .right form .inputContainer button:hover {
  border-color: #e3c496;
  background-color: transparent;
  color: #fff;
}
main .message {
  position: fixed;
  top: 5%;
  transform: translateX(100rem);
  right: 5%;
  color: #fff;
  z-index: 10;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.8rem;
}
main .message p {
  font-size: 1.8rem;
}
main .sucsesfullMessage {
  background-color: green;
}
main .errorMessage {
  background-color: red;
}
main .message.active {
  transform: translateX(0);
}
footer {
  padding: 8rem 0;
  box-sizing: border-box;
  background-color: #343434;
  display: flex;
  justify-content: center;
}
footer .container {
  display: flex;
  justify-content: space-between;
  width: 90%;
}
footer .container .block a {
  font: 3.2rem "Bold";
  line-height: 135%;
  color: #fff;
}
footer .container .block p {
  font-size: 2rem;
  line-height: 150%;
  color: #fff;
  margin-top: 2rem;
}
footer .container .block a.logo {
  font-family: "Regular";
}
footer .container .block .row {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 2rem;
}
footer .container .block .row a {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  margin-left: 2rem;
}
footer .container .block .row a svg {
  width: 100%;
  object-fit: contain;
}
.css-1nmdiq5-option:hover {
  color: #000 !important;
}
::-webkit-scrollbar {
  position: absolute;
  left: 50%;
  justify-content: center;
  width: 5px;
  max-height: 50px;
  border-radius: 12px;
}
::-webkit-scrollbar-track {
  background: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #e3c496;
  border-radius: 200px;
  width: 10px;
}
.mainColor {
  color: #e3c496;
}
.bold {
  font-family: "Bold";
}
.mobile {
  display: none;
}
@media screen and (max-width: 768px) {
  main section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 70vh;
    padding: 6rem 0;
    box-sizing: border-box;
    width: 95%;
    margin: 0 auto;
  }
  main .firstScreen {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../background/firstSection_mobile.webp);
    background-size: contain;
    width: 100%;
    min-height: 70rem;
  }
  main .firstScreen .container {
    display: flex;
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    width: 95%;
    min-height: unset;
  }
  main .firstScreen .container img.logo {
    width: 4.6rem;
    height: 4.6rem;
    object-fit: contain;
    margin-bottom: 2rem;
  }
  main .firstScreen .container h1 {
    color: #fff;
    font: 4.4rem "Bold";
    line-height: 120%;
    letter-spacing: -0.02em;
    text-align: center;
    margin-bottom: 2rem;
  }
  main .firstScreen .container h1 br {
    display: none;
  }
  main .firstScreen .container p {
    font: 2rem "Light";
    line-height: 132%;
    text-align: center;
    color: #fff;
    margin-bottom: 6rem;
  }
  main .firstScreen .container a {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: #e3c496;
    font: 1.6rem "Bold";
  }
  main .firstScreen .container a img {
    width: 2.3rem;
    object-fit: contain;
    margin-top: 2rem;
  }
  main .profit {
    padding: 6rem 0;
    box-sizing: border-box;
    width: 95%;
    margin: 0 auto;
  }
  main .profit h2 {
    font: 3rem "Bold";
    text-align: center;
    line-height: 110%;
    letter-spacing: -0.03em;
    color: #000;
    margin-bottom: 2rem;
  }
  main .profit .slide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  main .profit .slick-dots {
    display: flex !important;
    align-items: center;
    width: 100%;
    bottom: -2rem !important;
  }
  main .profit .slick-dots li {
    display: flex;
    flex-grow: 1;
    margin: 0;
  }
  main .profit .slick-dots button {
    width: unset;
    flex-grow: 1;
    height: 1rem;
    background-color: #d9d9d9;
  }
  main .profit .slick-dots button::before {
    display: none !important;
  }
  main .profit .slick-dots .slick-active button {
    background-color: #e3c496;
  }
  main .profit .slick-next {
    right: -5rem !important;
  }
  main .profit .slick-next::before {
    display: none;
  }
  main .profit .slick-prev {
    left: -5rem !important;
  }
  main .profit .slick-prev::before {
    display: none;
  }
  main .profit .slick-arrow {
    display: none !important;
  }
  main .profit .block {
    display: flex !important;
    flex-direction: column;
    width: 100% !important;
  }
  main .profit .block img {
    width: 100%;
    object-fit: contain;
  }
  main .profit .block .textPart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
    min-height: 21rem;
    background-color: #f9f3ea;
  }
  main .profit .block .textPart h3 {
    font: 1.8rem "Bold";
    line-height: 135%;
    width: 100%;
    margin-bottom: 2rem;
  }
  main .profit .block .textPart p {
    font: 1.6rem "Light";
    line-height: 130%;
    width: 100%;
  }
  main .profit .block:nth-child(2n) {
    flex-direction: column;
  }
  main .career .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  main .career .container .right {
    width: 100%;
    margin-top: 2rem;
  }
  main .career .container .right img {
    width: 100%;
    object-fit: contain;
  }
  main .career .container .left {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  main .career .container .left .mobile {
    display: none;
    width: 100%;
    object-fit: contain;
    margin: 2rem 0;
  }
  main .career .container .left h2 {
    font: 3rem "Bold";
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.03em;
    margin-bottom: 2rem;
  }
  main .career .container .left p.background {
    background-color: #e3c496;
    font: 1.6rem "Light";
    padding: 0.5rem 1rem;
    margin: 0 auto;
    box-sizing: border-box;
  }
  main .career .container .left h3 {
    margin-top: 4rem;
    font: 1.8rem "Bold";
    text-align: center;
    margin-bottom: 2rem;
  }
  main .career .container .left p {
    font: 1.6rem "Light";
    text-align: center;
  }
  main .start {
    width: 95%;
    margin: 0 auto;
  }
  main .start .container {
    padding: 6rem 2rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    justify-content: flex-end;
    min-height: 61rem;
    background-image: url(../background/start_mobile.webp);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    background-color: #333333;
  }
  main .start .container .left,
  main .start .container .right {
    width: 100%;
  }
  main .start .container .left h2 {
    font: 3rem "Bold";
    color: #fff;
    line-height: 110%;
    letter-spacing: -0.03rem;
    margin-bottom: 2rem;
  }
  main .start .container .left p {
    line-height: 135%;
    color: #fff;
  }
  main .start .container .right {
    margin-bottom: 6rem;
  }
  main .start .container .right p {
    font-size: 1.6rem;
    line-height: 135%;
    color: #fff;
    font: 1.8rem "Light";
  }
  main .question {
    position: relative;
  }
  main .question .row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  main .question .row .block {
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
    background-color: #f9f3ea;
    min-height: unset;
    margin-bottom: 2rem;
  }
  main .question .row .block .inRow {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 2rem;
  }
  main .question .row .block .inRow p {
    font-size: 3.6rem;
    line-height: 106%;
    color: #e3c496;
    width: unset;
  }
  main .question .row .block .inRow p.light {
    font-family: "Light";
  }
  main .question .row .block h3 {
    font: 1.8rem "Bold";
    line-height: 135%;
    color: #000;
    margin-bottom: 2rem;
    width: 80%;
  }
  main .question .row .block p {
    font-size: 1.6rem;
    line-height: 135%;
    width: 80%;
    color: #000;
    font-family: "Light";
  }
  main .question .row .block p.light {
    font-family: "Light";
  }
  main .question .block100 {
    display: flex;
    width: 100%;
    min-height: 62.5rem;
    background-color: #333;
    padding: 2.6rem;
    box-sizing: border-box;
    margin-top: 2rem;
  }
  main .question .block100 .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  main .question .block100 .container .left,
  main .question .block100 .container .right {
    width: 100%;
  }
  main .question .block100 .container .left {
    display: flex;
    justify-content: unset;
    flex-direction: column;
    align-items: flex-start;
  }
  main .question .block100 .container .left .inRow_mobile {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 2rem;
  }
  main .question .block100 .container .left .inRow_mobile p {
    font-size: 3.6rem;
    line-height: 106%;
    color: #e3c496;
    width: unset;
  }
  main .question .block100 .container .left .inRow_mobile p.light {
    font-family: "Light";
  }
  main .question .block100 .container .left h3 {
    font: 1.8rem "Bold";
    line-height: 135%;
    color: #e3c496;
    margin-bottom: 4rem;
  }
  main .question .block100 .container .left p {
    font: 1.6rem "Light";
    line-height: 135%;
    color: #fff;
    margin-bottom: 2rem;
  }
  main .question .block100 .container .left a {
    display: flex;
    width: 70%;
    margin-top: 1rem;
    justify-content: center;
    padding: 1.2rem 4.4rem;
    background-color: #e3c496;
    color: #333333;
    font: 1.6rem "SemiBold";
  }
  main .question .block100 .container .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 5rem;
  }
  main .question .block100 .container .right .inRow {
    display: none;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 4rem;
  }
  main .question .block100 .container .right .inRow p {
    font-size: 7.2rem;
    line-height: 106%;
    color: #e3c496;
    width: unset;
  }
  main .question .block100 .container .right .inRow p.light {
    font-family: "Light";
  }
  main .question .block100 .container .right img {
    width: 100%;
    max-width: 40rem;
    object-fit: contain;
  }
  main .history h2 {
    font: 3rem "Bold";
    line-height: 125%;
    letter-spacing: -0.03rem;
    text-align: center;
    color: #000;
    margin-bottom: 6rem;
  }
  main .history .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  main .history .container .column {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  main .history .container .column:nth-child(2n) {
    flex-direction: column-reverse;
  }
  main .history .container .column:nth-child(2n) img {
    width: 100%;
    object-fit: cover;
  }
  main .history .container .column:nth-child(2n) img:nth-child(1) {
    height: 53rem;
    margin-top: 2rem;
  }
  main .history .container .column:nth-child(2n) img:nth-child(2) {
    height: 35rem;
  }
  main .history .container .column:nth-child(3) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  main .history .container .column:nth-child(3) img {
    width: 48%;
    height: 35rem !important;
  }
  main .history .container .column img {
    width: 100%;
    object-fit: cover;
    margin-bottom: 2rem;
  }
  main .history .container .column img:nth-child(1) {
    height: 53rem;
    margin-bottom: 2rem;
  }
  main .history .container .column img:nth-child(2) {
    height: 35rem;
  }
  main .history a {
    display: flex;
    align-items: center;
    margin: 0 auto;
    margin-top: 2rem;
    font: 1.8rem "Bold";
    color: #e3c496;
    padding: 0 2rem;
    padding-bottom: 1rem;
    box-sizing: border-box;
    border-bottom: 0.3rem solid #e3c496;
  }
  main .history a img {
    margin-left: 2rem;
  }
  main .form {
    display: flex;
    flex-direction: column;
    height: unset;
    min-height: 82rem;
    background-image: url(../background/form-bg_mobile.webp);
    background-size: cover;
    justify-content: unset;
    background-repeat: no-repeat;
    width: 100%;
    padding: 0;
    padding-top: 4rem;
    box-sizing: border-box;
  }
  main .form .container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 0;
    height: 100%;
    box-sizing: border-box;
  }
  main .form .container .left,
  main .form .container .right {
    width: 100%;
  }
  main .form .container .left {
    width: 95%;
    margin: 0 auto;
    margin-bottom: 20rem;
  }
  main .form .container .left h2 {
    font: 3rem "Bold";
    line-height: 110%;
    letter-spacing: -0.03em;
    color: #000;
    margin-bottom: 2rem;
    margin-top: 0;
  }
  main .form .container .left p {
    font-size: 1.8rem;
    line-height: 135%;
    color: #000;
  }
  main .form .container .right {
    padding: 2rem 4rem;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    height: unset !important;
    background-color: rgba(52, 52, 52, 0.8);
    backdrop-filter: blur(1rem);
    padding-bottom: 6rem;
  }
  main .form .container .right form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  main .form .container .right form h3 {
    font: 3rem "Bold";
    color: #fff;
    margin-bottom: 2rem;
    text-align: center;
  }
  main .form .container .right form p {
    font: 1.8rem "Light";
    color: #b1b1b1;
    text-align: center;
  }
  main .form .container .right form p.light {
    font: 1rem "Light";
    color: #fff;
    text-align: left;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  main .form .container .right form .inputContainer {
    margin-top: 5rem;
  }
  main .form .container .right form .inputContainer input {
    width: 100%;
    background-color: transparent;
    font-size: 1.4rem;
    padding: 1rem 2rem;
    box-sizing: border-box;
    color: #fff;
    border-bottom: 0.2rem solid #fff;
    margin-bottom: 2rem;
  }
  main .form .container .right form .inputContainer input::placeholder {
    opacity: 0.5;
    color: #fff;
  }
  main .form .container .right form .inputContainer .css-13cymwt-control {
    background-color: transparent;
    border-color: transparent;
    border-bottom-color: #fff;
    color: #b1b1b1;
    font-size: 1.4rem;
  }
  main .form .container .right form .inputContainer .css-t3ipsp-control {
    background-color: transparent;
    border-color: transparent;
    border-bottom-color: #fff;
    color: #b1b1b1;
    font-size: 1.4rem;
    box-shadow: unset;
  }
  main .form .container .right form .inputContainer .css-t3ipsp-control:hover {
    border-color: transparent;
    border-bottom-color: #fff;
  }
  main .form .container .right form .inputContainer .css-1wy0on6 {
    display: none;
  }
  main .form .container .right form .inputContainer .css-1nmdiq5-menu {
    background-color: #4c4c4c;
    color: #fff;
    font-size: 1.4rem;
    border: none;
    box-shadow: none;
  }
  main .form .container .right form .inputContainer .css-19bb58m,
  main .form .container .right form .inputContainer .css-1dimb5e-singleValue {
    color: #fff;
  }
  main .form .container .right form .inputContainer .css-13cymwt-option {
    display: none;
    background-color: red;
  }
  main .form .container .right form .inputContainer [class*="option"] {
    color: #fff;
  }
  main .form .container .right form .inputContainer [class*="option"]:hover {
    background-color: #e3c496;
    color: #000;
  }
  main .form .container .right form .inputContainer .row {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  main .form .container .right form .inputContainer .row input {
    padding: 0;
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 2rem;
  }
  main .form .container .right form .inputContainer .row p {
    font-size: 1.3rem;
    text-align: left;
    color: #fff;
    font-family: "Regular";
  }
  main .form .container .right form .inputContainer button {
    background-color: #e3c496;
    color: #000;
    padding: 1rem;
    box-sizing: border-box;
    width: 100%;
    font: 1.8rem "SemiBold";
  }
  footer {
    padding: 8rem 0;
    box-sizing: border-box;
    background-color: #343434;
    display: flex;
    justify-content: center;
  }
  footer .container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 95%;
  }
  footer .container .block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10rem;
  }
  footer .container .block:nth-child(1) {
    margin-bottom: 0;
  }
  footer .container .block a {
    font: 2.4rem "Bold";
    line-height: 135%;
    color: #fff;
  }
  footer .container .block p {
    font-size: 1.8rem;
    line-height: 150%;
    color: #fff;
    margin-top: 2rem;
    text-align: center;
  }
  footer .container .block a.logo {
    font-family: "Regular";
  }
  footer .container .block .row {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
  }
  footer .container .block .row a {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    margin-left: 2rem;
  }
  footer .container .block .row a svg {
    width: 100%;
    object-fit: contain;
  }
}
button {
  border: 0.1rem solid transparent;
}
button:hover {
  border-color: #e3c496;
  background-color: #fff;
  color: #e3c496;
}
